import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js"
import Button from "components/CustomButtons/Button.js"

const useStyles = makeStyles(styles);

const BlogRoll = ({data, count}) => {
    const { edges: posts } = data.allMarkdownRemark
    const classes = useStyles();

    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <h2 className={classes.title}>Latest posts</h2>
          <GridContainer justify={'center'}>
            {posts &&
              posts.map(({ node: post }) => (
                <GridItem xs={12} sm={12} md={3} key={post.id}>
                  <Card align={'left'} /* post.frontmatter.featuredpost ? 'is-featured' : '' */>
                    {post.frontmatter.featuredimage ? (
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `${post.frontmatter.title}`,
                        }}
                      />
                    ) : null}
                    <CardBody>
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        <h4 className={classes.cardTitle}>
                          {post.frontmatter.title}
                          <br />
                          <small className={classes.smallTitle}>{post.frontmatter.date}</small>
                        </h4>
                      </Link>
                      <p>
                        {post.excerpt}
                        <br />
                        <br />
                        <Link to={post.fields.slug}>
                          <Button color="primary">Keep Reading</Button>
                        </Link>
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
          </GridContainer>
          <GridContainer justify={'flex-end'}>
            <GridItem xs={12} sm={12} md={3}>
              <Link to="/blog">
                <Button color={'rose'}>
                  Read more beautiful blog posts
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxHeight: 400, cropFocus: CENTER, maxWidth: 440, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
